<template>
  <abnormal-warning :datas="[3, 'viewDsaException', 'editDsaException']"></abnormal-warning>
</template>
<script>
import AbnormalWarning from '@/components/threshold/AbnormalWarning.vue'
export default {
  components: {
    AbnormalWarning,
  },
  data() {
    return {}
  },
}
</script>
